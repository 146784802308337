import {HostedInstanceResource} from "client/resources/hostedInstanceResource";
import {ReefResource} from "client/resources/reefResource";
import {BackgroundTaskResource} from "client/resources/backgroundTaskResource";
import {TestResource} from "client/resources/testResource";
import {Step} from "client/resources/step";
import session from "session";
import {DynamicWorkerInstanceResource} from "client/resources/dynamicWorkerInstanceResource";
import {DynamicWorkerServiceResource} from "client/resources/dynamicWorkerServiceResource";
import {InstanceLimitResource} from "client/resources/instanceLimitResource";
import {MessageBusMessageResource} from "./client/resources/messageBusMessageResource";
import {EnvironmentName} from "./client/resources/environmentName";
import {DynamicWorkerServiceRuntimeStatusResource} from "./client/resources/dynamicWorkerServiceRuntimeStatusResource";
import moment, {duration, Moment} from "moment";
import {HostedInstanceLookupResource} from "./client/resources/hostedInstanceLookupResource";

const hubLinks = {
    root: (reefResource: ReefResource) => `${reefResource.HubUrl}app#`,
};

function showLastMinutesInSumo(minutes: number) {
    return `startTime=-${minutes}m`;
}

type BackgroundTaskInput = Pick<BackgroundTaskResource, "Id" | "Started" | "Finished" | "TraceId" | "Created" >;

export function sumoQueryTimeRange(task: BackgroundTaskInput) {
    if (task.Started) {
        const startMillis = moment.utc(task.Started).valueOf();
        let result = `&startTime=${startMillis}`;

        if (task.Finished) {
            const endMillis = moment.utc(task.Finished).valueOf();
            result += `&endTime=${endMillis}`;
        }
        return result;
    }

    return `&${showLastMinutesInSumo(60)}`;
}

export function traceQueryTimeRange(task: BackgroundTaskInput) {
    const startSeconds = moment.utc(task.Created).add(-10, "minutes").unix();
    let result = `&trace_start_ts=${startSeconds}`;
    const endSeconds = moment.utc(task.Finished ?? moment.now()).add(10, "minutes").unix();
    result += `&trace_end_ts=${endSeconds}`;
    return result;
}


const sumoLogicLinks = {
    baseQueryUrl: (path: string) => `${session.configuration.SumoLogicPortalUrl}/ui/#/search/create?query=${path}`,
    sharedBaseQueryUrl: (path: string) => `${session.configuration.SumoLogicSharedPortalUrl}/ui/#/search/create?query=${path}`,
    clusterAutoScaler: (reef: ReefResource) => sumoLogicLinks.baseQueryUrl(encodeURIComponent(`_sourceCategory="hosted/${reef.Name}/azurelogs" _collector="${reef.Name}" | json field=_raw "category" | where %category = "cluster-autoscaler"`)),
    storageAccount: (reef: ReefResource) => sumoLogicLinks.baseQueryUrl(encodeURIComponent(`_sourceCategory="hosted/${reef.Name}/azurelogs" _collector="${reef.Name}" | json field=_raw "category" | where %category in ("StorageWrite","StorageRead", "StorageDelete")`)),
    instanceLogs: (instance: HostedInstanceResource) => sumoLogicLinks.baseQueryUrl(encodeURIComponent(`_index=cloudinstancelogs _sourceName="${instance.ShortId}"`)) + `&${showLastMinutesInSumo(60)}`,
    portalLogs: (environment: EnvironmentName) => sumoLogicLinks.baseQueryUrl(encodeURIComponent(`_index=cloudplatform_portal_logs | where environment = "${environment}"`)) + `&${showLastMinutesInSumo(60)}`,
    sharedPortalLogs: (environment: EnvironmentName) => sumoLogicLinks.sharedBaseQueryUrl(encodeURIComponent(`_index=cloudplatform_portal | where environment = "${environment}"`)) + `&${showLastMinutesInSumo(60)}`,
    mqsDashboard: () => session.configuration.SumoLogicMqsDashboardUrl,
    dynamicWorkerServiceLogs: (dynamicWorkersInstanceId: string) => sumoLogicLinks.baseQueryUrl(encodeURIComponent(`_index=cloudplatform_dynamicworkers_logs | where dynamicworkersinstanceid = "${dynamicWorkersInstanceId}" | where applicationset = "DynamicWorkers"`)) + `&${showLastMinutesInSumo(60)}`,
    backgroundTaskLogs: (backgroundTask: BackgroundTaskInput, environment: EnvironmentName) => sumoLogicLinks.baseQueryUrl(encodeURIComponent(`_index=cloudplatform_portal_logs | where Environment = "${environment}" | where BackgroundTaskId = "${backgroundTask.Id}"`)) + sumoQueryTimeRange(backgroundTask),
    instanceMetrics: (instance: HostedInstanceResource, reef: ReefResource) => `${session.configuration.SumoLogicPortalUrl}/ui/#/dashboardv2/${reef.SumoLogicInstanceDashboardId}?variables=shortid:${instance.ShortId}`,
    httpAccessLogs: (instance: HostedInstanceResource) => sumoLogicLinks.baseQueryUrl(encodeURIComponent(`(_index=hostedplatformlogs _sourceCategory=hosted/*/nginx "octopus-${instance.ShortId}") | parse "* nginx: *" as Controller, RequestJson | json field=RequestJson "status", "request", "remote_addr", "request_time" nodrop | fields -Controller`))
};

const honeycombLinks = {
    allPortalTraces: () => session.configuration.HoneycombPortalBaseUrl,
    singlePortalTrace: (traceQuery: string) => `${honeycombLinks.allPortalTraces()}/trace?trace_id=${traceQuery}`,
    backgroundTaskTrace: (backgroundTask: BackgroundTaskInput) => honeycombLinks.singlePortalTrace(encodeURIComponent(`${backgroundTask.TraceId?.split("-")[1]}`)) + `${traceQueryTimeRange(backgroundTask) ?? ""}`,
    instanceTraces: (instance: HostedInstanceResource) => `${session.configuration.HoneycombInstanceBaseUrl}?query={"filters":[{"column":"octopus.cloud.shortId","op":"=","value":"${instance.ShortId}"}]}`
}

const octofrontLinks = {
    subscriptionDetails: (instanceLimit: InstanceLimitResource) => `${session.configuration.OctofrontAdminUrl}/cloud-subscriptions/${instanceLimit.CloudSubscriptionId}`,
    supportUsers: (instanceLimit: InstanceLimitResource) => `${session.configuration.OctofrontAdminUrl}/OctopusId/SupportUsers?licenseSerial=${instanceLimit.CloudSubscriptionSerial}`
};

const instanceLinks = {
    domain: (instance: HostedInstanceResource, reef: ReefResource) => `${instance.DnsPrefix}.${reef.TopLevelDomain}`,
    homePage: (instance: HostedInstanceResource, reef: ReefResource) => `https://${instanceLinks.domain(instance, reef)}`,
};

const seqLinks = {
    url: (path: string) => `${session.configuration.SeqUrl}/#/${path}`,
    customerInstanceUrl: (path: string) => `${session.configuration.CustomerInstanceSeqUrl}/#/${path}`,
    eventsRestrictedTo: (expression: string, makeUrl: (path: string) => string) => makeUrl(`events?${expression}`),
    dashboard: (dashboardId: string) => seqLinks.url(`dashboards?dashboardId=${dashboardId}`),
    filterWithSignal: (signalId: string, makeUrl: (path: string) => string) => seqLinks.eventsRestrictedTo(`signal=${signalId}`, makeUrl),
    filterEvents: (filter: any, makeUrl: (path: string) => string) => {
        const filterString = encodeURIComponent(Object.keys(filter).map(t => t === "@EventType" ? `${t} = ${filter[t]}` : `${t} = '${filter[t]}'`).join(" and "));
        return seqLinks.eventsRestrictedTo(`filter=${filterString}`, makeUrl);
    },
    filterEventsWithTimeRange: (filter: any, timeRange: { from?: Moment, to?: Moment }, makeUrl: (path: string) => string) => {
        let timeUriComponents = "";
        if (timeRange.from?.isValid()) timeUriComponents += `&from=${timeRange.from?.toISOString()}`;
        if (timeRange.to?.isValid())   timeUriComponents += `&to=${timeRange.to?.toISOString()}`;
        return seqLinks.filterEvents(filter, (path: string) => { return makeUrl(path + timeUriComponents); } );
    },
    backgroundTaskLogFudgeFactor: duration(2, "minutes"),
    backgroundTaskTimeRange: (backgroundTask: BackgroundTaskResource) => {
        return {
            from: moment(backgroundTask.Created).subtract(seqLinks.backgroundTaskLogFudgeFactor),
              to: moment(backgroundTask.Finished).add(seqLinks.backgroundTaskLogFudgeFactor)
        };
    },
    backgroundTask: (backgroundTask: BackgroundTaskResource) => seqLinks.filterEventsWithTimeRange(
    { BackgroundTaskId: backgroundTask.Id }, seqLinks.backgroundTaskTimeRange(backgroundTask), seqLinks.url),
    backgroundTaskStep: (backgroundTask: BackgroundTaskResource, step: Step) => seqLinks.filterEventsWithTimeRange(
    { BackgroundTaskId: backgroundTask.Id, StepId: step.Id }, seqLinks.backgroundTaskTimeRange(backgroundTask), seqLinks.url),
    instanceLogs: (instance: HostedInstanceResource, reef: ReefResource) => seqLinks.filterEvents({
        ShortId: instance.ShortId,
        Reef: reef.Name
    }, seqLinks.customerInstanceUrl),
    instanceLogsWithTimeRange: (instance: HostedInstanceLookupResource, timeRange: { from?: Moment, to?: Moment }) => seqLinks.filterEventsWithTimeRange({
        Application: "Hosted Instance",
        ShortId: instance?.ShortId
    }, timeRange, seqLinks.customerInstanceUrl),
    portalLogs: (environment: EnvironmentName) => seqLinks.filterEvents({
        Application: "Cloud Portal",
        Environment: environment
    }, seqLinks.url),
    testDashboard: (test: TestResource) => seqLinks.dashboard(test.SeqDashboardId),
    testRawData: (test: TestResource) => seqLinks.filterWithSignal(test.SeqRawDataSignalId, seqLinks.url),
    dynamicWorkerService: (dynamicWorkersInstanceId: string) => seqLinks.filterEvents({
        ApplicationSet: "DynamicWorkers",
        DynamicWorkersInstanceId: dynamicWorkersInstanceId
    }, seqLinks.url),
    dynamicWorkerServiceDashboard: (workerService: DynamicWorkerServiceResource) => seqLinks.dashboard(workerService.SeqDashboardId),
    dynamicWorker: (worker: DynamicWorkerInstanceResource) => seqLinks.filterEvents({WorkerId: worker.Id}, seqLinks.url),
    dynamicWorkerInitialLeaseTime: (dynamicWorkersInstanceId: string) => seqLinks.filterEvents({
        DynamicWorkersInstanceId: dynamicWorkersInstanceId,
        "@EventType": "0x8C339B75"
    }, seqLinks.url),
    messageBusMessage: (message: MessageBusMessageResource) => seqLinks.filterEvents({MessageId: message.MessageId}, seqLinks.url)
};

const crowdStrikeLinks = {
    agent: (agentId: string) => `https://falcon.crowdstrike.com/hosts/hosts/host/${agentId}`
};

const betterUptimeAlertingLinks = {
    status: (instance: HostedInstanceResource) => `${session.configuration.BetterUptimeAlertingUrl}/${instance.BetterUptimeAlertingMonitorId}`
};

const betterUptimeRawUptimeLinks = {
    status: (instance: HostedInstanceResource) => `${session.configuration.BetterUptimeRawUptimeUrl}/${instance.BetterUptimeRawUptimeMonitorId}`
};

const azureMonitorLinks = {
    /**
     * Given a particular ARM resource ID for a SQL Server Elastic Pool, generates a link to Azure Metrics Explorer to show SQL Instance CPU Used over the past 24 hours
     * @param resourceId The ARM resource ID of the SQL Server Elastic Pool
     * @returns A link to the Azure Metrics Explorer in the Azure Portal
     */
    createElasticPoolSqlInstanceCpuUsedPercentLink(resourceId: string) {
        let url = session.configuration.AzurePortalUrl + "/blade/Microsoft_Azure_MonitoringMetrics/Metrics.ReactView/Referer/MetricsExplorer/ResourceId/{{ResourceId}}/TimeContext/%7B%22relative%22%3A%7B%22duration%22%3A86400000%7D%2C%22showUTCTime%22%3Afalse%2C%22grain%22%3A1%7D/ChartDefinition/%7B%22v2charts%22%3A%5B%7B%22metrics%22%3A%5B%7B%22resourceMetadata%22%3A%7B%22id%22%3A%22{{ResourceId}}%22%7D%2C%22name%22%3A%22sql_instance_cpu_percent%22%2C%22aggregationType%22%3A4%2C%22namespace%22%3A%22microsoft.sql%2Fservers%2Felasticpools%22%2C%22metricVisualization%22%3A%7B%22displayName%22%3A%22SQL%20instance%20CPU%20percent%22%7D%7D%5D%2C%22title%22%3A%22Avg%20SQL%20instance%20CPU%20percent%20for%20{{ResourceName}}%22%2C%22titleKind%22%3A1%2C%22visualization%22%3A%7B%22chartType%22%3A2%2C%22legendVisualization%22%3A%7B%22isVisible%22%3Atrue%2C%22position%22%3A2%2C%22hideSubtitle%22%3Afalse%7D%2C%22axisVisualization%22%3A%7B%22x%22%3A%7B%22isVisible%22%3Atrue%2C%22axisType%22%3A2%7D%2C%22y%22%3A%7B%22isVisible%22%3Atrue%2C%22axisType%22%3A1%2C%22min%22%3A0%2C%22max%22%3A100%7D%7D%7D%7D%5D%7D";
        url = url.replace(/\{\{ResourceId\}\}/g, encodeURIComponent(resourceId));
        url = url.replace(/\{\{ResourceName\}\}/g, encodeURIComponent(this.getResourceNameFromResourceId(resourceId)));
        return url;
    },

    /**
     * Given a particular ARM resource ID for a SQL Server Elastic Pool, generates a link to Azure Metrics Explorer to show CPU Used over the past 24 hours
     * @param resourceId The ARM resource ID of the SQL Server Elastic Pool
     * @returns A link to the Azure Metrics Explorer in the Azure Portal
     */
    createElasticPoolCpuUsedPercentLink(resourceId: string) {
        let url = session.configuration.AzurePortalUrl + "/blade/Microsoft_Azure_MonitoringMetrics/Metrics.ReactView/Referer/MetricsExplorer/ResourceId/{{ResourceId}}/TimeContext/%7B%22relative%22%3A%7B%22duration%22%3A86400000%7D%2C%22showUTCTime%22%3Afalse%2C%22grain%22%3A1%7D/ChartDefinition/%7B%22v2charts%22%3A%5B%7B%22metrics%22%3A%5B%7B%22resourceMetadata%22%3A%7B%22id%22%3A%22{{ResourceId}}%22%7D%2C%22name%22%3A%22cpu_percent%22%2C%22aggregationType%22%3A4%2C%22namespace%22%3A%22microsoft.sql%2Fservers%2Felasticpools%22%2C%22metricVisualization%22%3A%7B%22displayName%22%3A%22CPU%20percentage%22%7D%7D%5D%2C%22title%22%3A%22Avg%20CPU%20percentage%20for%20{{ResourceName}}%22%2C%22titleKind%22%3A1%2C%22visualization%22%3A%7B%22chartType%22%3A2%2C%22legendVisualization%22%3A%7B%22isVisible%22%3Atrue%2C%22position%22%3A2%2C%22hideSubtitle%22%3Afalse%7D%2C%22axisVisualization%22%3A%7B%22x%22%3A%7B%22isVisible%22%3Atrue%2C%22axisType%22%3A2%7D%2C%22y%22%3A%7B%22isVisible%22%3Atrue%2C%22axisType%22%3A1%2C%22min%22%3A0%2C%22max%22%3A100%7D%7D%7D%7D%5D%7D";
        url = url.replace(/\{\{ResourceId\}\}/g, encodeURIComponent(resourceId));
        url = url.replace(/\{\{ResourceName\}\}/g, encodeURIComponent(this.getResourceNameFromResourceId(resourceId)));
        return url;
    },

    /**
     * Given a particular ARM resource ID for a SQL Server Elastic Pool, generates a link to Azure Metrics Explorer to show Data IO over the past 24 hours
     * @param resourceId The ARM resource ID of the SQL Server Elastic Pool
     * @returns A link to the Azure Metrics Explorer in the Azure Portal
     */
    createElasticPoolDataIoUsedPercentLink(resourceId: string) {
        let url = session.configuration.AzurePortalUrl + "/blade/Microsoft_Azure_MonitoringMetrics/Metrics.ReactView/Referer/MetricsExplorer/ResourceId/{{ResourceId}}/TimeContext/%7B%22relative%22%3A%7B%22duration%22%3A86400000%7D%2C%22showUTCTime%22%3Afalse%2C%22grain%22%3A1%7D/ChartDefinition/%7B%22v2charts%22%3A%5B%7B%22metrics%22%3A%5B%7B%22resourceMetadata%22%3A%7B%22id%22%3A%22{{ResourceId}}%22%7D%2C%22name%22%3A%22physical_data_read_percent%22%2C%22aggregationType%22%3A4%2C%22namespace%22%3A%22microsoft.sql%2Fservers%2Felasticpools%22%2C%22metricVisualization%22%3A%7B%22displayName%22%3A%22Data%20IO%20percentage%22%7D%7D%5D%2C%22title%22%3A%22Avg%20Data%20IO%20percentage%20for%20{{ResourceName}}%22%2C%22titleKind%22%3A1%2C%22visualization%22%3A%7B%22chartType%22%3A2%2C%22legendVisualization%22%3A%7B%22isVisible%22%3Atrue%2C%22position%22%3A2%2C%22hideSubtitle%22%3Afalse%7D%2C%22axisVisualization%22%3A%7B%22x%22%3A%7B%22isVisible%22%3Atrue%2C%22axisType%22%3A2%7D%2C%22y%22%3A%7B%22isVisible%22%3Atrue%2C%22axisType%22%3A1%2C%22min%22%3A0%2C%22max%22%3A100%7D%7D%7D%7D%5D%7D";
        url = url.replace(/\{\{ResourceId\}\}/g, encodeURIComponent(resourceId));
        url = url.replace(/\{\{ResourceName\}\}/g, encodeURIComponent(this.getResourceNameFromResourceId(resourceId)));
        return url;
    },

    /**
     * Given a particular ARM resource ID for a SQL Server Elastic Pool, generates a link to Azure Metrics Explorer to show Log IO over the past 24 hours
     * @param resourceId The ARM resource ID of the SQL Server Elastic Pool
     * @returns A link to the Azure Metrics Explorer in the Azure Portal
     */
    createElasticPoolLogIoUsedPercentLink(resourceId: string) {
        let url = session.configuration.AzurePortalUrl + "/blade/Microsoft_Azure_MonitoringMetrics/Metrics.ReactView/Referer/MetricsExplorer/ResourceId/{{ResourceId}}/TimeContext/%7B%22relative%22%3A%7B%22duration%22%3A86400000%7D%2C%22showUTCTime%22%3Afalse%2C%22grain%22%3A1%7D/ChartDefinition/%7B%22v2charts%22%3A%5B%7B%22metrics%22%3A%5B%7B%22resourceMetadata%22%3A%7B%22id%22%3A%22{{ResourceId}}%22%7D%2C%22name%22%3A%22log_write_percent%22%2C%22aggregationType%22%3A4%2C%22namespace%22%3A%22microsoft.sql%2Fservers%2Felasticpools%22%2C%22metricVisualization%22%3A%7B%22displayName%22%3A%22Log%20IO%20percentage%22%7D%7D%5D%2C%22title%22%3A%22Avg%20Log%20IO%20percentage%20for%20{{ResourceName}}%22%2C%22titleKind%22%3A1%2C%22visualization%22%3A%7B%22chartType%22%3A2%2C%22legendVisualization%22%3A%7B%22isVisible%22%3Atrue%2C%22position%22%3A2%2C%22hideSubtitle%22%3Afalse%7D%2C%22axisVisualization%22%3A%7B%22x%22%3A%7B%22isVisible%22%3Atrue%2C%22axisType%22%3A2%7D%2C%22y%22%3A%7B%22isVisible%22%3Atrue%2C%22axisType%22%3A1%2C%22min%22%3A0%2C%22max%22%3A100%7D%7D%7D%7D%5D%7D";
        url = url.replace(/\{\{ResourceId\}\}/g, encodeURIComponent(resourceId));
        url = url.replace(/\{\{ResourceName\}\}/g, encodeURIComponent(this.getResourceNameFromResourceId(resourceId)));
        return url;
    },

    /**
     * Given a particular ARM resource ID for a SQL Server Elastic Pool, generates a link to Azure Metrics Explorer to show Data Storage Used over the past 24 hours
     * @param resourceId The ARM resource ID of the SQL Server Elastic Pool
     * @returns A link to the Azure Metrics Explorer in the Azure Portal
     */
    createElasticPoolDataSpaceUsedPercentLink(resourceId: string) {
        let url = session.configuration.AzurePortalUrl + "/blade/Microsoft_Azure_MonitoringMetrics/Metrics.ReactView/Referer/MetricsExplorer/ResourceId/{{ResourceId}}/TimeContext/%7B%22relative%22%3A%7B%22duration%22%3A86400000%7D%2C%22showUTCTime%22%3Afalse%2C%22grain%22%3A1%7D/ChartDefinition/%7B%22v2charts%22%3A%5B%7B%22metrics%22%3A%5B%7B%22resourceMetadata%22%3A%7B%22id%22%3A%22{{ResourceId}}%22%7D%2C%22name%22%3A%22storage_percent%22%2C%22aggregationType%22%3A4%2C%22namespace%22%3A%22microsoft.sql%2Fservers%2Felasticpools%22%2C%22metricVisualization%22%3A%7B%22displayName%22%3A%22Data%20space%20used%20percent%22%7D%7D%5D%2C%22title%22%3A%22Avg%20Data%20space%20used%20percent%20for%20{{ResourceName}}%22%2C%22titleKind%22%3A1%2C%22visualization%22%3A%7B%22chartType%22%3A2%2C%22legendVisualization%22%3A%7B%22isVisible%22%3Atrue%2C%22position%22%3A2%2C%22hideSubtitle%22%3Afalse%7D%2C%22axisVisualization%22%3A%7B%22x%22%3A%7B%22isVisible%22%3Atrue%2C%22axisType%22%3A2%7D%2C%22y%22%3A%7B%22isVisible%22%3Atrue%2C%22axisType%22%3A1%2C%22min%22%3A0%2C%22max%22%3A100%7D%7D%7D%7D%5D%7D"
        url = url.replace(/\{\{ResourceId\}\}/g, encodeURIComponent(resourceId));
        url = url.replace(/\{\{ResourceName\}\}/g, encodeURIComponent(this.getResourceNameFromResourceId(resourceId)));
        return url;
    },

    /**
     * Given a particular ARM resource ID for a SQL Server Elastic Pool, generates a link to Azure Metrics Explorer to show Data Storage Allocated over the past 24 hours
     * @param resourceId The ARM resource ID of the SQL Server Elastic Pool
     * @returns A link to the Azure Metrics Explorer in the Azure Portal
     */
    createElasticPoolDataSpaceAllocatedPercentLink(resourceId: string) {
        let url = session.configuration.AzurePortalUrl + "/blade/Microsoft_Azure_MonitoringMetrics/Metrics.ReactView/Referer/MetricsExplorer/ResourceId/{{ResourceId}}/TimeContext/%7B%22relative%22%3A%7B%22duration%22%3A86400000%7D%2C%22showUTCTime%22%3Afalse%2C%22grain%22%3A1%7D/ChartDefinition/%7B%22v2charts%22%3A%5B%7B%22metrics%22%3A%5B%7B%22resourceMetadata%22%3A%7B%22id%22%3A%22{{ResourceId}}%22%7D%2C%22name%22%3A%22allocated_data_storage_percent%22%2C%22aggregationType%22%3A3%2C%22namespace%22%3A%22microsoft.sql%2Fservers%2Felasticpools%22%2C%22metricVisualization%22%3A%7B%22displayName%22%3A%22Data%20space%20allocated%20percent%22%7D%7D%5D%2C%22title%22%3A%22Max%20Data%20space%20allocated%20percent%20for%20alexreef4sql1ep1%22%2C%22titleKind%22%3A1%2C%22visualization%22%3A%7B%22chartType%22%3A2%2C%22legendVisualization%22%3A%7B%22isVisible%22%3Atrue%2C%22position%22%3A2%2C%22hideSubtitle%22%3Afalse%7D%2C%22axisVisualization%22%3A%7B%22x%22%3A%7B%22isVisible%22%3Atrue%2C%22axisType%22%3A2%7D%2C%22y%22%3A%7B%22isVisible%22%3Atrue%2C%22axisType%22%3A1%7D%7D%7D%7D%5D%7D";
        url = url.replace(/\{\{ResourceId\}\}/g, encodeURIComponent(resourceId));
        url = url.replace(/\{\{ResourceName\}\}/g, encodeURIComponent(this.getResourceNameFromResourceId(resourceId)));
        return url;
    },

    /**
     * Given a particular ARM resource ID for a Storage Account, generates a link to Azure Metrics Explorer to show Storage Account requests that indicate throttling over the past 24 hours
     * @param resourceId The ARM resource ID of the Storage Account
     * @returns A link to the Azure Metrics Explorer in the Azure Portal
     */
    createStorageThrottlingMetricsLink(resourceId: string) {
        let url = session.configuration.AzurePortalUrl + "/blade/Microsoft_Azure_MonitoringMetrics/Metrics.ReactView/Referer/MetricsExplorer/ResourceId/{{ResourceId}}/TimeContext/%7B%22relative%22%3A%7B%22duration%22%3A86400000%7D%2C%22showUTCTime%22%3Afalse%2C%22grain%22%3A1%7D/ChartDefinition/%7B%22v2charts%22%3A%5B%7B%22metrics%22%3A%5B%7B%22resourceMetadata%22%3A%7B%22id%22%3A%22{{ResourceId}}%22%7D%2C%22name%22%3A%22Transactions%22%2C%22aggregationType%22%3A1%2C%22namespace%22%3A%22microsoft.storage%2Fstorageaccounts%22%2C%22metricVisualization%22%3A%7B%22displayName%22%3A%22Transactions%22%2C%22resourceDisplayName%22%3A%22{{ResourceName}}%22%7D%7D%5D%2C%22title%22%3A%22Azure%20Storage%20Account%20transactions%20for%20{{ResourceName}}%20that%20indicate%20throttling%22%2C%22titleKind%22%3A2%2C%22visualization%22%3A%7B%22chartType%22%3A2%2C%22legendVisualization%22%3A%7B%22isVisible%22%3Atrue%2C%22position%22%3A2%2C%22hideSubtitle%22%3Afalse%2C%22hideHoverCard%22%3Afalse%2C%22hideLabelNames%22%3Atrue%7D%2C%22axisVisualization%22%3A%7B%22x%22%3A%7B%22isVisible%22%3Atrue%2C%22axisType%22%3A2%7D%2C%22y%22%3A%7B%22isVisible%22%3Atrue%2C%22axisType%22%3A1%7D%7D%7D%2C%22filterCollection%22%3A%7B%22filters%22%3A%5B%7B%22key%22%3A%22ResponseType%22%2C%22operator%22%3A0%2C%22values%22%3A%5B%22SuccessWithShareIopsThrottling%22%2C%22ClientShareIopsThrottlingError%22%5D%7D%5D%7D%7D%5D%7D";
        url = url.replace(/\{\{ResourceId\}\}/g, encodeURIComponent(resourceId));
        url = url.replace(/\{\{ResourceName\}\}/g, encodeURIComponent(this.getResourceNameFromResourceId(resourceId)));
        return url;
    },

    /**
     * Given a particular ARM resource ID, return the name of the resource
     * @param resourceId The ARM resource ID to retrieve the name from
     * @returns The name of the ARM resource
     */
    getResourceNameFromResourceId(resourceId: string) {
        // Resource names are the last component of the ResourceId
        if (!resourceId) return "";
        const lastSlash = resourceId.lastIndexOf("/");
        const resourceName = resourceId.substring(lastSlash + 1);
        return resourceName;
    }
}

export const externalSystemLinks = {
    hub: hubLinks,
    octofront: octofrontLinks,
    instance: instanceLinks,
    seq: seqLinks,
    crowdstrike: crowdStrikeLinks,
    betterUptime:
        {
            alertingLinks: betterUptimeAlertingLinks,
            rawUptimeLinks: betterUptimeRawUptimeLinks
        },
    sumoLogic: sumoLogicLinks,
    honeycomb: honeycombLinks,

    /** Generates deep links into the Azure Portal to view metrics */
    azureMonitorLinks: azureMonitorLinks,
};

export default externalSystemLinks;
